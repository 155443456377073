define("discourse/plugins/automation/admin/models/discourse-automation-field", ["exports", "@glimmer/tracking", "@ember-compat/tracked-built-ins"], function (_exports, _tracking, _trackedBuiltIns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let DiscourseAutomationField = _exports.default = (_class = class DiscourseAutomationField {
    constructor() {
      _initializerDefineProperty(this, "acceptsPlaceholders", _descriptor, this);
      _initializerDefineProperty(this, "component", _descriptor2, this);
      _initializerDefineProperty(this, "extra", _descriptor3, this);
      _initializerDefineProperty(this, "isDisabled", _descriptor4, this);
      _initializerDefineProperty(this, "isRequired", _descriptor5, this);
      _initializerDefineProperty(this, "metadata", _descriptor6, this);
      _initializerDefineProperty(this, "name", _descriptor7, this);
      _initializerDefineProperty(this, "targetType", _descriptor8, this);
      _initializerDefineProperty(this, "targetName", _descriptor9, this);
    }
    static create(template, target) {
      let json = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      const field = new DiscourseAutomationField();
      field.acceptsPlaceholders = template.accepts_placeholders;
      field.acceptedContexts = template.accepted_contexts;
      field.targetName = target.name;
      field.targetType = target.type;
      field.name = template.name;
      field.component = template.component;
      field.isDisabled = template.read_only;

      // backwards compatibility with forced scriptable fields
      if (field.isDisabled) {
        field.metadata.value = template.default_value || template.value || json?.metadata?.value;
      } else {
        field.metadata.value = template.value || json?.metadata?.value || template.default_value;
      }

      // null is not a valid value for metadata.value
      if (field.metadata.value === null) {
        field.metadata.value = undefined;
      }
      field.isRequired = template.is_required;
      field.extra = new _trackedBuiltIns.TrackedObject(template.extra);
      return field;
    }
    toJSON() {
      return {
        name: this.name,
        target: this.targetType,
        component: this.component,
        metadata: this.metadata
      };
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "acceptsPlaceholders", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "component", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "extra", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new _trackedBuiltIns.TrackedObject();
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isDisabled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isRequired", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "metadata", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new _trackedBuiltIns.TrackedObject();
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "name", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "targetType", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "targetName", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});